@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

body{
    /* background-color: white; */
}

.coupleName {
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
    text-decoration: none;
}

.main__solo {
    /* border: 1px solid gray; */
    padding: 20px;
    margin: 10px;
    text-align: center;
    /* background-color: rgb(233, 231, 231); */
    border-radius: 10px;
    box-shadow: 5px 10px 18px 0px #888888;
    transition: 0.2s;
}

.main__solo:hover {
    transform: scale(1.03);
    transform-origin: center;
    box-shadow: 5px 10px 18px 3px #888888;
    background-color:#f5f3f3;
}

.card__heading {
    text-decoration: none;
    color: black;
    text-decoration: none;
}

.solo__images {
    transition: 0.2s;
}

/* .solo__images:hover {
    transform: scale(1.03);
    transform-origin: center;
    box-shadow: 5px 10px 18px 3px #888888;
} */
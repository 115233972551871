body {
  overflow-x: hidden;
    /* background-color: black; */
    /* background-image: url("../images/splashScreen/bg.png");
    background-repeat: no-repeat;
    background-size: cover; */
    height: 100%;
}

.centerr {
    position: absolute;
  top: 15%;
}

@media (min-width: 640px) {
  .centerr {
      top: 25%;
  }
}

.centerrr {
  vertical-align: middle
}

.footer1 {
  --tw-text-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-text-opacity));
  /* background-color: rgb(59, 59, 59); */
}
.footer {
  --tw-text-opacity: 1;
  background-color: rgba(210, 215, 219, var(--tw-text-opacity));
  /* background-color: rgb(59, 59, 59); */
}

.profile {
  text-align: center;
  border: 1px solid #ebebeb;
  border-top: 2px solid;
  padding-top: 10px;
  border-radius: 10px;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.buttonMero {
  background-color: #FFFFFF;
}

@media (min-width: 990px) {
  .showMe {
      display: inline-flex;
  }
}
@media (min-width: 570px) {
  .showMEE {
      display: inline-flex;
  }
  .hideMe {
    display: none;
  }
}

.mobile-nav {
  position: absolute;
  right: 0;
  margin-top: 2rem;
  margin-right: 2rem;
}

@media only screen and (min-width: 768px) {
  .mobile-nav {
      display: none;
  }
}
@media only screen and (max-width: 768px) {
  .wrap-nav {
      display: none;
  }
}